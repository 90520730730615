<template>
<div>
  <div class="form-body" style="padding-left: 60rem">
      <br />
      <h4>基础信息</h4>
      <br />
      <el-form :model="form" ref="form" label-width="100rem"
               style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
        <el-form-item label="设置年月" prop="month">
          <p>{{ form.month }}</p>
        </el-form-item>
        <el-form-item label="校区" prop="grade_info">
          <p>{{ form.school_info }}</p>
        </el-form-item>
        <el-form-item label="年级" prop="grade_info">
          <p>{{ form.grade_info }}</p>
        </el-form-item>
        <el-form-item label="科目" prop="subject_info">
          <p>{{ form.subject_info }}</p>
        </el-form-item>
        <br />
        <h4>学习习惯完成率</h4>
        <br />
        <el-form-item v-for="item in form.knack_list" :label="item.name" :prop="item.key">
          <p>{{ item.value }}</p>
        </el-form-item>
      </el-form>
      <div class="footer-button">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      backType: 0,  //1为保存后返回，此时需要刷新list; 0为取消后返回，此时不需要刷新
      form: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(){
      this.$_axios2('/api/educational-routine/routine-detail?id=' + this.$route.query.id ,{logic:1}).then(res => {
        this.form = res.data
      })
    },
  },
}
</script>

<style scoped>

</style>
